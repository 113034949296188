/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BookedSubscriptionsPageDto } from '../models';
// @ts-ignore
import { CompanyPageDto } from '../models';
// @ts-ignore
import { ProjectPageDto } from '../models';
// @ts-ignore
import { ResellerDto } from '../models';
// @ts-ignore
import { ResellerPageDto } from '../models';
// @ts-ignore
import { UpdateResellerDto } from '../models';
// @ts-ignore
import { UserPageDto } from '../models';
/**
 * ResellersApi - axios parameter creator
 * @export
 */
export const ResellersApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookedSubscriptionsForReseller: async (id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBookedSubscriptionsForReseller.');
            }
            const localVarPath = `/resellers/{id}/bookedsubscriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesForReseller: async (id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCompaniesForReseller.');
            }
            const localVarPath = `/resellers/{id}/companies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsForReseller: async (id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProjectsForReseller.');
            }
            const localVarPath = `/resellers/{id}/projects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the current
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReseller: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reseller`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResellers: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/resellers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForReseller: async (id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUsersForReseller.');
            }
            const localVarPath = `/resellers/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateResellerDto} updateReseller 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchReseller: async (id: string, updateReseller: UpdateResellerDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchReseller.');
            }
            // verify required parameter 'updateReseller' is not null or undefined
            if (updateReseller === null || updateReseller === undefined) {
                throw new RequiredError('updateReseller','Required parameter updateReseller was null or undefined when calling patchReseller.');
            }
            const localVarPath = `/resellers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateReseller !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateReseller !== undefined ? updateReseller : {})
                : (updateReseller || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResellersApi - functional programming interface
 * @export
 */
export const ResellersApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookedSubscriptionsForReseller(id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookedSubscriptionsPageDto>> {
            const localVarAxiosArgs = await ResellersApiAxiosParamCreator(axiosClientConfiguration).getBookedSubscriptionsForReseller(id, page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesForReseller(id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPageDto>> {
            const localVarAxiosArgs = await ResellersApiAxiosParamCreator(axiosClientConfiguration).getCompaniesForReseller(id, page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsForReseller(id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectPageDto>> {
            const localVarAxiosArgs = await ResellersApiAxiosParamCreator(axiosClientConfiguration).getProjectsForReseller(id, page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return the current
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReseller(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResellerDto>> {
            const localVarAxiosArgs = await ResellersApiAxiosParamCreator(axiosClientConfiguration).getReseller(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResellers(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResellerPageDto>> {
            const localVarAxiosArgs = await ResellersApiAxiosParamCreator(axiosClientConfiguration).getResellers(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersForReseller(id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPageDto>> {
            const localVarAxiosArgs = await ResellersApiAxiosParamCreator(axiosClientConfiguration).getUsersForReseller(id, page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateResellerDto} updateReseller 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchReseller(id: string, updateReseller: UpdateResellerDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ResellersApiAxiosParamCreator(axiosClientConfiguration).patchReseller(id, updateReseller, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ResellersApi - factory interface
 * @export
 */
export const ResellersApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookedSubscriptionsForReseller(id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<BookedSubscriptionsPageDto> {
            return ResellersApiFp(axiosClientConfiguration).getBookedSubscriptionsForReseller(id, page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesForReseller(id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<CompanyPageDto> {
            return ResellersApiFp(axiosClientConfiguration).getCompaniesForReseller(id, page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsForReseller(id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<ProjectPageDto> {
            return ResellersApiFp(axiosClientConfiguration).getProjectsForReseller(id, page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the current
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReseller(options?: any): AxiosPromise<ResellerDto> {
            return ResellersApiFp(axiosClientConfiguration).getReseller(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResellers(translate?: string, options?: any): AxiosPromise<ResellerPageDto> {
            return ResellersApiFp(axiosClientConfiguration).getResellers(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForReseller(id: string, page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<UserPageDto> {
            return ResellersApiFp(axiosClientConfiguration).getUsersForReseller(id, page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateResellerDto} updateReseller 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchReseller(id: string, updateReseller: UpdateResellerDto, translate?: string, options?: any): AxiosPromise<void> {
            return ResellersApiFp(axiosClientConfiguration).patchReseller(id, updateReseller, translate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getBookedSubscriptionsForReseller operation in ResellersApi.
 * @export
 * @interface ResellersApiGetBookedSubscriptionsForResellerRequest
 */
export interface ResellersApiGetBookedSubscriptionsForResellerRequest {
    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetBookedSubscriptionsForReseller
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof ResellersApiGetBookedSubscriptionsForReseller
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ResellersApiGetBookedSubscriptionsForReseller
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetBookedSubscriptionsForReseller
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetBookedSubscriptionsForReseller
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof ResellersApiGetBookedSubscriptionsForReseller
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetBookedSubscriptionsForReseller
     */
    readonly translate?: string
}

/**
 * Request parameters for getCompaniesForReseller operation in ResellersApi.
 * @export
 * @interface ResellersApiGetCompaniesForResellerRequest
 */
export interface ResellersApiGetCompaniesForResellerRequest {
    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetCompaniesForReseller
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof ResellersApiGetCompaniesForReseller
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ResellersApiGetCompaniesForReseller
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetCompaniesForReseller
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetCompaniesForReseller
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof ResellersApiGetCompaniesForReseller
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetCompaniesForReseller
     */
    readonly translate?: string
}

/**
 * Request parameters for getProjectsForReseller operation in ResellersApi.
 * @export
 * @interface ResellersApiGetProjectsForResellerRequest
 */
export interface ResellersApiGetProjectsForResellerRequest {
    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetProjectsForReseller
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof ResellersApiGetProjectsForReseller
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ResellersApiGetProjectsForReseller
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetProjectsForReseller
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetProjectsForReseller
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof ResellersApiGetProjectsForReseller
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetProjectsForReseller
     */
    readonly translate?: string
}

/**
 * Request parameters for getResellers operation in ResellersApi.
 * @export
 * @interface ResellersApiGetResellersRequest
 */
export interface ResellersApiGetResellersRequest {
    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetResellers
     */
    readonly translate?: string
}

/**
 * Request parameters for getUsersForReseller operation in ResellersApi.
 * @export
 * @interface ResellersApiGetUsersForResellerRequest
 */
export interface ResellersApiGetUsersForResellerRequest {
    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetUsersForReseller
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof ResellersApiGetUsersForReseller
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ResellersApiGetUsersForReseller
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetUsersForReseller
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetUsersForReseller
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof ResellersApiGetUsersForReseller
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ResellersApiGetUsersForReseller
     */
    readonly translate?: string
}

/**
 * Request parameters for patchReseller operation in ResellersApi.
 * @export
 * @interface ResellersApiPatchResellerRequest
 */
export interface ResellersApiPatchResellerRequest {
    /**
     * 
     * @type {string}
     * @memberof ResellersApiPatchReseller
     */
    readonly id: string

    /**
     * 
     * @type {UpdateResellerDto}
     * @memberof ResellersApiPatchReseller
     */
    readonly updateReseller: UpdateResellerDto

    /**
     * 
     * @type {string}
     * @memberof ResellersApiPatchReseller
     */
    readonly translate?: string
}

/**
 * ResellersApi - object-oriented interface
 * @export
 * @class ResellersApi
 * @extends {BaseAPI}
 */
export class ResellersApi extends BaseAPI {
    /**
     * 
     * @param {ResellersApiGetBookedSubscriptionsForResellerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResellersApi
     */
    public getBookedSubscriptionsForReseller(requestParameters: ResellersApiGetBookedSubscriptionsForResellerRequest, options?: any) {
        return ResellersApiFp(this.axiosClientConfiguration).getBookedSubscriptionsForReseller(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResellersApiGetCompaniesForResellerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResellersApi
     */
    public getCompaniesForReseller(requestParameters: ResellersApiGetCompaniesForResellerRequest, options?: any) {
        return ResellersApiFp(this.axiosClientConfiguration).getCompaniesForReseller(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResellersApiGetProjectsForResellerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResellersApi
     */
    public getProjectsForReseller(requestParameters: ResellersApiGetProjectsForResellerRequest, options?: any) {
        return ResellersApiFp(this.axiosClientConfiguration).getProjectsForReseller(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the current
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResellersApi
     */
    public getReseller(options?: any) {
        return ResellersApiFp(this.axiosClientConfiguration).getReseller(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResellersApiGetResellersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResellersApi
     */
    public getResellers(requestParameters: ResellersApiGetResellersRequest = {}, options?: any) {
        return ResellersApiFp(this.axiosClientConfiguration).getResellers(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResellersApiGetUsersForResellerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResellersApi
     */
    public getUsersForReseller(requestParameters: ResellersApiGetUsersForResellerRequest, options?: any) {
        return ResellersApiFp(this.axiosClientConfiguration).getUsersForReseller(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResellersApiPatchResellerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResellersApi
     */
    public patchReseller(requestParameters: ResellersApiPatchResellerRequest, options?: any) {
        return ResellersApiFp(this.axiosClientConfiguration).patchReseller(requestParameters.id, requestParameters.updateReseller, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }
}
