import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { HidingPermissionBase, MyPermissionsStore } from "@smallstack/user-components";

@Directive({
  selector: "[showForResellerPermission]",
  standalone: true
})
export class ShowForResellerPermissionDirective extends HidingPermissionBase {
  @Input()
  public set showForResellerPermission(permission: string) {
    this.permission = permission;
  }

  constructor(vcr: ViewContainerRef, template: TemplateRef<any>, auth: MyPermissionsStore) {
    super(vcr, template, (permission) => auth.hasResellerPermission$(permission));
  }
}
