import { NgModule } from "@angular/core";
import { SmallstackUserModule } from "@smallstack/user-components";
import { ShowWithCompanyPermissionDirective } from "./show-for-company-permission.directive";
import { ShowForProjectPermissionDirective } from "./show-for-project-permission.directive";
import { ShowForResellerPermissionDirective } from "./show-for-reseller-permission.directive";

@NgModule({
  imports: [
    SmallstackUserModule,
    ShowForProjectPermissionDirective,
    ShowWithCompanyPermissionDirective,
    ShowForResellerPermissionDirective
  ],
  exports: [ShowForProjectPermissionDirective, ShowWithCompanyPermissionDirective, ShowForResellerPermissionDirective]
})
export class SmallstackAuthorizationModule {}
