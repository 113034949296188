import { ChangeDetectorRef, Directive, TemplateRef, ViewContainerRef, effect, input } from "@angular/core";
import { MyPermissionsStore } from "@smallstack/user-components";

@Directive({
  selector: "[showForProjectPermission]",
  standalone: true
})
export class ShowForProjectPermissionDirective {
  public showForProjectPermission = input.required<string>();
  public showForProjectPermissionProjectId = input<string>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    authService: MyPermissionsStore,
    private cdr: ChangeDetectorRef
  ) {
    effect(() => {
      this.updateView(
        authService.hasProjectPermission(this.showForProjectPermission(), this.showForProjectPermissionProjectId())
      );
    });
  }

  private updateView(shouldDisplay: boolean): void {
    this.viewContainerRef.clear();
    if (shouldDisplay) this.viewContainerRef.createEmbeddedView(this.templateRef);
    this.cdr.markForCheck();
  }
}
